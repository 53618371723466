<template>
  <div class="container mypage withTop">
    <section class="contents Rgt growthRgt" ref="Rgt" v-loading="loading">
      <article class="box">
        <p>재배품목 <span>*</span></p>
        <el-select v-model="crops">
          <el-option value="딸기" label="딸기"></el-option>
          <el-option value="토마토" label="토마토"></el-option>
          <el-option value="사과" label="사과"></el-option>
          <el-option value="고추" label="고추"></el-option>
          <el-option value="포도" label="포도"></el-option>
          <el-option value="레드향" label="레드향"></el-option>
          <el-option value="상추" label="상추"></el-option>
        </el-select>
        <p>시료 <span>*</span></p>
        <el-select v-model="sample">
          <el-option value="작물 1" label="작물 1"></el-option>
          <el-option value="작물 2" label="작물 2"></el-option>
          <el-option value="작물 3" label="작물 3"></el-option>
          <el-option value="작물 4" label="작물 4"></el-option>
          <el-option value="작물 5" label="작물 5"></el-option>
          <el-option value="작물 6" label="작물 6"></el-option>
          <el-option value="작물 7" label="작물 7"></el-option>
          <el-option value="작물 8" label="작물 8"></el-option>
          <el-option value="작물 9" label="작물 9"></el-option>
          <el-option value="작물 10" label="작물 10"></el-option>
          <el-option value="작물 11" label="작물 11"></el-option>
          <el-option value="작물 12" label="작물 12"></el-option>
          <el-option value="작물 13" label="작물 13"></el-option>
          <el-option value="작물 14" label="작물 14"></el-option>
          <el-option value="작물 15" label="작물 15"></el-option>
        </el-select>

        <p>초장(cm) <span>*</span></p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="totalLT" />
        <p>잎개수(개) <span>*</span></p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="leafCount" />
        <p>잎길이(cm) <span>*</span></p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="leafLength" />
        <p>잎너비(cm) <span>*</span></p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="leafWidth" />
        <p>관부직경(mm)</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="crownDmt" />
        <p>잎자루길이(cm)</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="ptlLt" />
        <p>화방출뢰기</p>
        <Datepicker v-model="fclBuddingDt" :language="ko" :format="customFormatter" placeholder="선택" @focusin.native="handleFocus"></Datepicker>

        <p>개화기</p>
        <Datepicker v-model="blpr" :language="ko" :format="customFormatter" placeholder="선택" @focusin.native="handleFocus"></Datepicker>

        <p>개화수(개)</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="planCount" />
        <p>착과화방(화방)</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="fristFclu" />
        <p>착과수(개) <span>*</span></p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="fristCount" />
        <p>액아 발생기</p>
        <Datepicker v-model="axbdOcprd" :language="ko" :format="customFormatter" placeholder="선택" @focusin.native="handleFocus"></Datepicker>

        <p>주당 열매수(개)</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="plantFruitCount" />
        <p>과장(cm)</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="fruitLt" />
        <p>과중(g)</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="flshWt" />
        <p>과폭(cm)</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="flshAra" />
        <p>당도(Brix)</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="brx" />
        <p>산도(pH)</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="acidity" />
        <p>수확량(kg)</p>
        <input type="number" @click="handleFocus" pattern="\d*" v-model="hvst" />
      </article>

      <button class="point full" @click="submit">
        {{ editMode == true ? "수정하기" : "등록하기" }}
      </button>
    </section>
  </div>
</template>
<script>
import { createGrowth, fetchGrowthDetail, updateGrowth, createModel } from "@/api/growth";
import { fetchDeviceInfoForrn400, fetchDeviceInfoForrn171 } from "@/api/device";
import { mapState } from "vuex";
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
export default {
  components: { Datepicker },

  data() {
    return {
      ko: ko,
      moment: moment,
      editMode: false,
      growthId: "",
      farmer: "",
      crops: "",
      sample: "",
      registerDate: "",
      totalLT: "",
      leafCount: "",
      leafLength: "",
      leafWidth: "",
      crownDmt: "",
      ptlLt: "",
      fclBuddingDt: "",
      blpr: "",
      planCount: "",
      fristFclu: "",
      fristCount: "",
      axbdOcprd: "",
      plantFruitCount: "",
      fruitLt: "",
      flshWt: "",
      flshAra: "",
      brx: "",
      acidity: "",
      hvst: "",
      createdAt: "",
      updatedAt: "",
      farm: "",
      temp: "",
      humidity: "",
      co2: "",
      loading: false,
      flag_400: false,
      flag_171: false,
      defaultFlag: true,
    };
  },
  computed: {
    ...mapState(["userId", "userType", "growthTotal", "mainFarm"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 1);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "생육데이터등록");
  },
  mounted() {
    if (this.$route.query.id) {
      this.growthId = this.$route.query.id;
      this.editMode = true;
      this.getGrowthDetail();
    }
    if (this.mainFarm) {
      this.crops = this.mainFarm.crops;
      this.farm = this.mainFarm._id;
      this.getDeviceInfoForrn171();
      this.getDeviceInfoForrn400();
    }
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    handleFocus() {
      this.$refs.Rgt.style.paddingBottom = "200px";
      document.activeElement.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    async getGrowthDetail() {
      fetchGrowthDetail(this.growthId).then((res) => {
        if (res.data.status == 200) {
          this.farmer = res.data.data.farmer;
          this.crops = res.data.data.crops;
          this.sample = res.data.data.sample;
          this.registerDate = res.data.data.registerDate;
          this.totalLT = res.data.data.totalLT;
          this.leafCount = res.data.data.leafCount;
          this.leafLength = res.data.data.leafLength;
          this.leafWidth = res.data.data.leafWidth;
          this.crownDmt = res.data.data.crownDmt;
          this.ptlLt = res.data.data.ptlLt;
          this.fclBuddingDt = res.data.data.fclBuddingDt;
          this.blpr = res.data.data.blpr;
          this.planCount = res.data.data.planCount;
          this.fristFclu = res.data.data.fristFclu;
          this.fristCount = res.data.data.fristCount;
          this.axbdOcprd = res.data.data.axbdOcprd;
          this.plantFruitCount = res.data.data.plantFruitCount;
          this.fruitLt = res.data.data.fruitLt;
          this.flshWt = res.data.data.flshWt;
          this.flshAra = res.data.data.flshAra;
          this.brx = res.data.data.brx;
          this.acidity = res.data.data.acidity;
          this.hvst = res.data.data.hvst;
          this.updatedAt = res.data.data.updatedAt;
          this.createdAt = res.data.data.createdAt;
        }
      });
    },
    getDeviceInfoForrn400() {
      this.sensors = [];
      let data = {
        farm: this.farm,
      };
      fetchDeviceInfoForrn400(data).then(async (res) => {
        if (res.data.status == 200 && this.defaultFlag == true) {
          if (res.data.data.status == false) {
            this.flag_400 = false;
            return await this.getDeviceInfoForrn400();
          } else {
            this.temp = res.data.data.sensors[0].channels[0].ch_value;
            this.humidity = res.data.data.sensors[0].channels[1].ch_value;
            this.flag_400 = true;
          }
        }
      });
    },
    getDeviceInfoForrn171() {
      this.co2Sensors = [];
      let data = {
        farm: this.farm,
      };

      fetchDeviceInfoForrn171(data).then(async (res) => {
        if (res.data.status == 200 && this.defaultFlag == true) {
          if (res.data.data.status == false) {
            this.flag_171 = false;
            return await this.getDeviceInfoForrn171();
          } else {
            this.co2 = res.data.data.sensors[0].channels[0].ch_value;
            this.flag_171 = true;
          }
        }
      });
    },

    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },

    submit() {
      if (this.crops == "") {
        return this.openAlert("품목을 입력해 주세요.");
      } else if (this.sample == "") {
        return this.openAlert("샘플작물을 선택해 주세요.");
      } else if (this.totalLT == "") {
        return this.openAlert("초장을 입력해 주세요.");
      } else if (this.leafCount == "") {
        return this.openAlert("잎개수를 입력해 주세요.");
      } else if (this.leafLength == "") {
        return this.openAlert("잎길이를 입력해 주세요.");
      } else if (this.leafWidth == "") {
        return this.openAlert("잎너비를 입력해 주세요.");
      } else if (this.fristCount == "") {
        return this.openAlert("착과수를 입력해 주세요.");
      }

      if (this.farmer == "") {
        this.farmer = this.mainFarm.farmer._id;
      }

      // 디바이스정보(온도,습도,co2) 에러가 발생해서 데이터가 없는 경우,
      // AI model에서 에러를 처리하기 때문에, 등록을 막음.
      if (this.flag_171 == false || this.flag_400 == false) {
        this.openAlert("서버오류입니다. 다시 등록해 주세요.");
        this.defaultFlag = false;
        this.$router.replace("/growthList");
        return;
      }

      let data = {
        growthId: this.growthId,
        farmer: this.farmer,
        farm: this.farm,
        co2: this.co2,
        temp: this.temp,
        humidity: this.humidity,
        crops: this.crops,
        sample: this.sample,
        totalLT: this.totalLT,
        leafCount: this.leafCount,
        leafLength: this.leafLength,
        leafWidth: this.leafWidth,
        crownDmt: this.crownDmt,
        ptlLt: this.ptlLt,
        fclBuddingDt: this.fclBuddingDt,
        blpr: this.blpr,
        planCount: this.planCount,
        fristFclu: this.fristFclu,
        fristCount: this.fristCount,
        axbdOcprd: this.axbdOcprd,
        plantFruitCount: this.plantFruitCount,
        fruitLt: this.fruitLt,
        flshWt: this.flshWt,
        flshAra: this.flshAra,
        brx: this.brx,
        acidity: this.acidity,
        hvst: this.hvst,
        updatedAt: this.updatedAt,
        createdAt: this.createdAt,
      };

      if (this.editMode == true) {
        updateGrowth(data).then(async (res) => {
          if (res.data.status == 200) {
            this.loading = true;
            let farm = {
              farmId: this.farm,
            };
            await createModel(farm).then((res) => {
              if (res.data.status == 200) {
                if (this.userType == "EXAMINER") {
                  this.$router.replace({ name: "growthList", query: { id: this.farmer } });
                } else {
                  this.$router.replace("/growthList");
                }
              }
            });
          } else {
            this.openAlert(res.data.message);
          }
        });
      } else {
        createGrowth(data).then(async (res) => {
          if (res.data.status == 200) {
            this.loading = true;
            let farm = {
              farmId: this.farm,
            };
            await createModel(farm).then((res) => {
              if (res.data.status == 200) {
                this.$router.replace("/growthList");
              }
            });
          } else {
            this.openAlert(res.data.message);
          }
        });
      }
    },
  },
};
</script>
